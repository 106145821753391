<template>
<div class="wrapper" wrapper="">
  <div id="title-solution" class="title">
    <h5 style="padding: 1em"><strong> -- Les solutions --</strong> </h5>
  </div>

  <div id="content-solution" class="content">


    <h5>L'action de <strong style="color:#6887ff;">sobriété énergétique</strong> ayant le plus d'influence
      sur la baisse des émissions carbone du
      numérique est d'<strong style="color:#6887ff;">augmenter
      la durée de vie de nos appareils.</strong><br><br>

      Cependant, nous devons également réduire nos consommations de GES de la phase d'utilisation,
      puisqu'en réalité, pour le numérique <strong style="color:#6887ff;">
      nous ne pouvons pas dissocier l'utilisation de la production.</strong><br><br>

      En effet, lorsque l'on regarde une vidéo en streaming, les données
      consommées proviennent de centres de données et sont transportées
      dans un réseau. Ces infrastructures sont coûteuses en énergie lors
      de leur fabrication et de leur maintenance.<br><br>

      De plus, une <strong style="color:#6887ff;">utilisation accrue du numérique</strong> implique
      un développement croissant d'infrastructures.<br><br>


      Voici quelques conseils pour diminuer votre impact carbone lors
      de l'utilisation : <br><br>

      <ul>
        <li>Limiter le visionnage et l'écoute en streaming</li>
        <li>Diminuer la résolution des vidéos pour le visionnage</li>
        <li>Stopper la pub sur les sites web</li>
        <li>Télécharger avec modération</li>
        <li>Utiliser le wifi plutôt que le réseau mobile</li>
      </ul>

    </h5>

  </div>

</div>
</template>

<script>
export default {
  name: "Solution"
}
</script>

<style scoped>

</style>